import { Tab, Tabs } from "@material-ui/core";
import classNames from "classnames";
import { differenceInDays, subDays } from "date-fns";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { ACCOUNT_CATEGORY } from "../../commons/enum";
import { useMobileHeaderTabsStyles } from "../../commons/transactions.common";
import { LoadingBackdrop, PageBody, PageBodyContent } from "../../components";
import ConnectBankModal, {
  DefaultConnectBank,
} from "../../components/connect-bank-modal";
import HeaderComponent from "../../components/HeaderComponent/header-component";
import LinkedAccountCarousel from "../../components/linked-account-carousel";
import LinkedBank from "../../components/linked-bank";
import LinkedAccount from "../../components/linked_account";
import TransactionFilterContext from "../../context/transaction-filter-context";
import DateRangePicker from "../../features/transactions/date-range-picker";
import {
  useDetailAccountList,
  useIsNotConnectingToAnyBank,
  useLinkedBankAccountData,
} from "../../hooks/account.hooks";
import { useIsDesktopSmall, useIsTablet } from "../../hooks/common.hooks";
import "../../scss/transactions.scss";
import { checkIsDemoClient } from "../../utils";
import { useTabStyles } from "../../utils/styles-util";
import AccountView from "./account-view";
import CombinedView from "./combined-view";
import transactionVideo from "../../videos/transaction.mp4";
import { transactionDateFilterTabs as tabs } from "./components/date-filter";

const DISPLAYED_ACCOUNT_CATEGORIES = [
  ACCOUNT_CATEGORY.ASSETS_NOT_SHARES_AND_SUPERS,
  ACCOUNT_CATEGORY.CREDIT,
  ACCOUNT_CATEGORY.LOAN,
  ACCOUNT_CATEGORY.OTHERS,
];

const TransactionsPage = ({ location }) => {
  const isTablet = useIsTablet();
  const [dateRangeTime, setDateRangeTime] = useState([
    {
      startDate: new Date(),
      endDate: subDays(new Date(), 7),
      key: "selection",
    },
  ]);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [dialogState, setDialogState] = useState(false);

  const handleClose = () => {
    setDialogState(false);
    if (selectedDateRange) {
      setDateRangeTime(selectedDateRange);
    }
  };
  const { account: initialAccountId = "combined" } = queryString.parse(
    location.search
  );
  const isDesktopSmall = useIsDesktopSmall();
  const tabsStyles = useTabStyles();
  const mobileHeaderTabsStyles = useMobileHeaderTabsStyles();

  const linkedBankAccountData = useLinkedBankAccountData();

  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const { numberOfDays } = selectedTab;
  const handleTabChange = (value) => {
    if (value !== "Custom Date Range") {
      setSelectedTab(tabs.find((tab) => tab.title === value));
    } else {
      setDialogState(true);
    }
  };

  const handleConfirmDate = () => {
    const dateRange = differenceInDays(
      dateRangeTime[0].endDate,
      dateRangeTime[0].startDate
    );
    setSelectedTab({
      title: "Custom Date Range",
      fromDate: dateRangeTime[0].startDate,
      toDate: dateRangeTime[0].endDate,
      numberOfDays: dateRange,
    });
    handleClose();
    setSelectedDateRange(dateRangeTime);
  };

  const isNotConnectingToAnyBank = useIsNotConnectingToAnyBank();
  const { isLoading: isGettingAccount } = useDetailAccountList();
  const [selectedAccountId, setSelectedAccountId] = useState(initialAccountId);

  useEffect(() => {
    if (linkedBankAccountData) {
      let found = false;
      DISPLAYED_ACCOUNT_CATEGORIES.forEach((accountCategory) => {
        const accounts = linkedBankAccountData[accountCategory] || [];
        if (
          accounts.find((account) => account.accountId === selectedAccountId)
        ) {
          found = true;
        }
      });
      if (!found) {
        setSelectedAccountId("combined");
      }
    }
  }, [linkedBankAccountData, selectedAccountId]);

  const renderAccountCombinedItem = (accountCategory) => {
    if (accountCategory !== ACCOUNT_CATEGORY.ASSETS_NOT_SHARES_AND_SUPERS) {
      return undefined;
    }
    return (
      <LinkedAccount
        cursor
        shouldHideValue
        accountId="combined"
        isSelected={selectedAccountId === "combined"}
        name="All Accounts"
        onClick={() => {
          setSelectedAccountId("combined");
        }}
      />
    );
  };

  const renderPageBody = () => {
    if (selectedAccountId === "combined") {
      return (
        <CombinedView
          tabs={tabs}
          selectedTab={selectedTab}
          onTabChange={handleTabChange}
        />
      );
    }
    return (
      <AccountView
        accountId={selectedAccountId}
        tabs={tabs}
        selectedTab={selectedTab}
        onTabChange={handleTabChange}
      />
    );
  };

  return (
    <TransactionFilterContext.Provider
      value={{
        selectedTab,
        setSelectedTab,
        handleConfirmDate,
        handleTabChange,
      }}
    >
      {isGettingAccount && <LoadingBackdrop />}
      <div className="page-container transactions-page">
        <div className="page-content">
          {!isNotConnectingToAnyBank && !isDesktopSmall && (
            <LinkedBank
              filteredCategories={DISPLAYED_ACCOUNT_CATEGORIES}
              title={`Last ${numberOfDays} Days`}
              value="Date Range"
              cursor
              selectedAccountId={selectedAccountId}
              onSelectedAccountIdChange={setSelectedAccountId}
              renderAccountCombinedItem={renderAccountCombinedItem}
            />
          )}

          <PageBody>
            {isNotConnectingToAnyBank ? (
              <>
                {!isTablet && <HeaderComponent />}
                <ConnectBankModal
                  videoBackground={transactionVideo}
                  callToActionButton={<DefaultConnectBank />}
                />
              </>
            ) : (
              <>
                {!isTablet && (
                  <HeaderComponent
                    headerContent={
                      <Tabs
                        value={selectedTab.title}
                        onChange={(event, value) => {
                          handleTabChange(value);
                        }}
                        indicatorColor="secondary"
                        textColor="inherit"
                        variant="scrollable"
                        scrollButtons="auto"
                        TabIndicatorProps={{ children: <span /> }}
                        classes={{
                          root: tabsStyles.tabs,
                          flexContainer: tabsStyles.tabsFlexContainer,
                          indicator: classNames(
                            tabsStyles.indicator,
                            mobileHeaderTabsStyles.indicator
                          ),
                        }}
                      >
                        {tabs.map((tab) => (
                          <Tab
                            key={tab.title}
                            value={tab.title}
                            label={tab.title}
                            classes={{
                              root: tabsStyles.tab,
                              selected: tabsStyles.selectedTab,
                            }}
                            disabled={
                              checkIsDemoClient() &&
                              tab.title === "Custom Date Range"
                            }
                          />
                        ))}
                      </Tabs>
                    }
                  />
                )}

                <PageBodyContent>
                  {isDesktopSmall && (
                    <LinkedAccountCarousel
                      filteredCategories={DISPLAYED_ACCOUNT_CATEGORIES}
                      selectedAccountId={selectedAccountId}
                      onSelectedAccountIdChange={setSelectedAccountId}
                      renderAccountCombinedItem={renderAccountCombinedItem}
                    />
                  )}
                  {renderPageBody()}
                  <DateRangePicker
                    handleSetDateRange={setDateRangeTime}
                    dateRangeTime={dateRangeTime}
                    handleCloseDialog={handleClose}
                    dialogState={dialogState}
                    handleConfirmDate={handleConfirmDate}
                  />
                </PageBodyContent>
              </>
            )}
          </PageBody>
        </div>
      </div>
    </TransactionFilterContext.Provider>
  );
};

export default TransactionsPage;
